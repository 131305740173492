<template>
  <div class="exam-page">
    <SnackBar :is-visible="isSnackbarVisible"></SnackBar>
    <ContentSidebarLayout>
      <div slot="main-content" class="main-content">
        <FeedbackComponent
          :key="answerSheetItemId"
          buttons-variant="black"
          placeholder="Puedes agregar un comentario opcional sobre el ejercicio..."
          :data-test-id-prefix="`answer-sheet-item`"
          class="main-content__feedback"
          @send-feedback="(feedback) => sendFeedback({ ...feedback, ...{ answerSheetItemId } })"
        />

        <AnswerSheetItem
          ref="exerciseRef"
          v-model="selectedAnswerId"
          :answer-sheet-id="answerSheetId"
          :answer-sheet-item-id="answerSheetItemId"
          :answer-sheet-item-number="answerSheetItemNumber"
          :answers="answers"
          :exercise="exercise"
          :next-answer-sheet-item-id="nextAnswerSheetItemId"
          :is-evaluate-button-disabled="isEvaluateButtonDisabled"
          data-test-id="answer-sheet-item"
          @load-next-answer-sheet-item="evaluateAnswerSheetItem"
        />

        <div class="main-content__hints_bottom_sheet">
          <VerticalSlide>
            <ExerciseHintsBottomSheet
              v-show="isHintsBottomSheetVisible"
              :last-hint-index-to-load="lastHintIndexToLoad"
              :exercise-hints="hints"
              :total-hints="totalHints"
              data-test-id="exercise-hints-bottom-sheet"
              @load-next-hint="getHint()"
              @send-feedback="sendFeedback"
            />
          </VerticalSlide>
        </div>

        <HintsFloatingButton
          v-show="isHintsFloatingButtonVisible"
          class="main-content__floating-button"
          data-test-id="show-hints-bottom-sheet-floating-button"
          @click="showHintsBottomSheet()"
        />
      </div>

      <div slot="sidebar">
        <ExerciseHintsSidebarBox
          v-show="isHintsSidebarBoxVisible"
          :exercise-hints="hints"
          :last-hint-index-to-load="lastHintIndexToLoad"
          :total-hints="totalHints"
          data-test-id="exercise-hints-sidebar-box"
          @load-next-hint="getHint()"
          @send-feedback="sendFeedback"
        />
      </div>
    </ContentSidebarLayout>

    <ExerciseFeedbackComponent
      v-model="isExerciseFeedbackVisible"
      :is-exercise-correct="isExerciseCorrect"
      :is-report-disabled="isReportIssueDisabled"
      @load-next-answer-sheet-item="loadNextAnswerSheetItem"
      @report-issue-question-clicked="openReportIssueModal"
    >
      <slot> Siguiente </slot>
    </ExerciseFeedbackComponent>

    <EvaluateExerciseModal
      v-model="isEvaluateExerciseModalComponentVisible"
      data-test-id="course-price-modal"
      @load-next-answer-sheet-item="loadNextAnswerSheetItem"
    />
    <InsufficientCoinsModalComponent
      v-model="isInsufficientCoinsModalComponent"
      data-test-id="insufficient-coins-modal"
    />
    <DailyExamModal
      v-model="isDailyExamModalVisible"
      :title="dailyExamModalTitle"
      :description="dailyExamModalDescription"
      :button-text="dailyExamModalButtonText"
      :is-correct="dailyExamModalIsCorrect"
      data-test-id="daily-exam-modal"
      @evaluate-answer-sheet-item="hideDailyExamModal"
    ></DailyExamModal>
    <ReportIssueModal
      v-model="isReportIssueModalVisible"
      data-test-id="report-issue-modal"
      :answer-sheet-item-id="answerSheetItemId"
      @send-report-issue="sendReportIssue"
      @hide-modal="showFeedbackComponent"
    >
    </ReportIssueModal>
  </div>
</template>

<script>
import ContentSidebarLayout from "@/components/ContentSidebarLayout.vue";
import AnswerSheetItem from "@/use_cases/show_exam/AnswerSheetItemComponent.vue";
import VerticalSlide from "@/transitions/VerticalSlide.vue";
import ExerciseHintsBottomSheet from "@/components/exercise_hints/ExerciseHintsBottomSheet.vue";
import HintsFloatingButton from "@/components/HintsFloatingButton.vue";
import ExerciseHintsSidebarBox from "@/components/exercise_hints/ExerciseHintsSidebarBox.vue";
import EvaluateAnswerSheetItem from "@/use_cases/show_exam/EvaluateAnswerSheetItem";
import GetHint from "@/use_cases/show_exam/GetHint";
import ExerciseFeedbackComponent from "@/use_cases/show_exam/ExerciseFeedbackComponent.vue";
import EvaluateExerciseModal from "@/use_cases/show_exam/EvaluateExerciseModalComponent.vue";
import InsufficientCoinsModalComponent from "@/components/InsufficientCoinsModalComponent.vue";
import InsufficientCoins from "@/exceptions/InsufficientCoins";
import DailyExamModal from "@/use_cases/show_exam/daily_exam/DailyExamModalComponent.vue";
import ReportIssueModal from "@/use_cases/show_exam/report_an_issue/ReportIssueModal.vue";
import SendFeedback from "@/services/send_feedback/SendFeedback";
import SnackBar from "@/components/snack_bar/SnackBar.vue";
import FeedbackComponent from "@/components/feedback_component/FeedbackComponent.vue";

const dailyExamPath = /\/s\/pregunta-del-dia/;

export default {
  name: "ExamPage",
  components: {
    FeedbackComponent,
    SnackBar,
    ReportIssueModal,
    DailyExamModal,
    AnswerSheetItem,
    ContentSidebarLayout,
    VerticalSlide,
    ExerciseHintsBottomSheet,
    HintsFloatingButton,
    ExerciseHintsSidebarBox,
    ExerciseFeedbackComponent,
    EvaluateExerciseModal,
    InsufficientCoinsModalComponent,
  },
  props: {
    answerSheetId: {
      type: String,
      required: true,
    },
    answerSheetItemId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isHintsBottomSheetVisible: false,
      isExerciseFeedbackVisible: false,
      hints: [],
      lastHintIndexToLoad: 0,
      /** @type {{ text: string, imageUrl: string }} * */
      exercise: {},
      /** @type {{ id: number, text: string, imageUrl: string } []} * */
      answers: [],
      answerSheetItemNumber: 0,
      previousAnswerSheetItemId: null,
      nextAnswerSheetItemId: null,
      initialTimestamp: null,
      selectedAnswerId: null,
      unlockedHints: [],
      nextHintId: null,
      totalHints: 0,
      evaluateExerciseText: "",
      evaluateExerciseColor: "",
      isEvaluateExerciseModalComponentVisible: false,
      isEvaluateButtonDisabled: false,
      isInsufficientCoinsModalComponent: false,
      isExerciseCorrect: false,
      isDailyExam: false,
      isDailyExamModalVisible: false,
      isReportIssueModalVisible: false,
      dailyExamModalTitle: "",
      dailyExamModalDescription: "",
      dailyExamModalButtonText: "",
      dailyExamModalIsCorrect: false,
      isSnackbarVisible: false,
      isReportIssueDisabled: false,
      isPersistent: true,
    };
  },
  computed: {
    isHintsFloatingButtonVisible() {
      return this.hasHints && !this.isHintsBottomSheetVisible;
    },
    isHintsSidebarBoxVisible() {
      return this.hasHints;
    },
    hasHints() {
      return this.totalHints > 0;
    },
  },
  watch: {
    $route() {
      this.initializeData();
      this.scrollToTop();
    },
  },
  async created() {
    await this.initializeData();
  },
  mounted() {
    this.checkExamType();
    this.emitLoadFinished();
  },
  methods: {
    async initializeData() {
      await this.getAnswerSheetItem();
      this.initialTimestamp = Date.now();
      this.refreshMathJax();
    },
    async getHint() {
      try {
        const hint = await GetHint.getHint({ answerSheetItemId: this.answerSheetItemId, hintId: this.nextHintId });
        this.$emit("update-coins-balance");
        this.hints.push(hint);
        this.nextHintId = hint.nextHint;
        this.lastHintIndexToLoad = this.hints.length;
      } catch (e) {
        if (e instanceof InsufficientCoins) {
          this.showInsufficientCoinsModalComponent();
        }
      }
    },
    async getAnswerSheetItem() {
      ({
        exercise: this.exercise,
        answers: this.answers,
        nextAnswerSheetItemId: this.nextAnswerSheetItemId,
        previousAnswerSheetItemId: this.previousAnswerSheetItemId,
        selectedAnswerId: this.selectedAnswerId,
        answerSheetItemNumber: this.answerSheetItemNumber,
        totalHints: this.totalHints,
        unlockedHints: this.unlockedHints,
      } = await EvaluateAnswerSheetItem.getAnswerSheetItem({
        answerSheetId: this.answerSheetId,
        answerSheetItemId: this.answerSheetItemId,
      }));
      if (this.selectedAnswerId != null) {
        this.isEvaluateExerciseModalComponentVisible = true;
      }

      this.hints = this.unlockedHints;
      if (this.hints.length > 0) {
        this.nextHintId = this.hints[this.hints.length - 1].next_hint;
      }
      this.lastHintIndexToLoad = this.unlockedHints.length;
    },
    refreshMathJax() {
      if (process.env.NODE_ENV !== "testing") {
        this.$nextTick(() => {
          window.MathJax.typesetPromise();
        });
      }
    },
    emitLoadFinished() {
      this.$emit("load-finished");
    },
    resetHints() {
      this.lastHintIndexToLoad = 0;
      this.totalHints = 0;
      this.nextHintId = null;
    },
    async loadNextAnswerSheetItem() {
      this.isHintsBottomSheetVisible = false;
      this.isEvaluateButtonDisabled = false;
      this.isExerciseFeedbackVisible = false;
      this.isReportIssueDisabled = false;
      await this.loadAnswerSheetItem(this.nextAnswerSheetItemId);
    },
    async loadPreviousAnswerSheetItem() {
      await this.loadAnswerSheetItem(this.previousAnswerSheetItemId);
    },
    async loadAnswerSheetItem(answerSheetItemId) {
      this.resetHints();
      if (answerSheetItemId === null) {
        await this.evaluateAnswerSheet();
        await this.$router.push({
          name: "AnswerSheetResultsSummaryPage",
          params: {
            answerSheetId: this.answerSheetId,
          },
        });
      } else {
        await this.$router.push({
          name: "ExamPage",
          params: {
            answerSheetId: this.answerSheetId,
            answerSheetItemId,
          },
        });
      }
    },
    async evaluateAnswerSheetItem() {
      // eslint-disable-next-line camelcase
      const response = await EvaluateAnswerSheetItem.evaluateAnswerSheetItem({
        answerSheetId: this.answerSheetId,
        answerSheetItemId: this.answerSheetItemId,
        selectedAnswerId: this.selectedAnswerId,
        initialTimestamp: this.initialTimestamp,
      });

      if (this.isDailyExam) {
        const isCorrect = response.is_correct;
        this.evaluateExerciseText = isCorrect ? "Respuesta Correcta" : "Respuesta Incorrecta";
        this.evaluateExerciseColor = isCorrect ? "exercise-correct-feedback" : "exercise-incorrect-feedback";
        this.dailyExamModalTitle = isCorrect ? "¡FELICIDADES!" : "¡SIGUE INTENTANDO!";
        this.dailyExamModalDescription = isCorrect
          ? `Te has ganado ${response.messages[0].reward.amount} monedas`
          : "Vuelve mañana para una nueva pregunta";
        this.dailyExamModalButtonText = isCorrect ? "RECLAMAR PREMIO" : "CONTINUAR";
        this.dailyExamModalIsCorrect = !!isCorrect;
        this.isDailyExamModalVisible = true;
      }

      this.isExerciseCorrect = response.is_correct;

      this.isEvaluateButtonDisabled = true;
      this.isExerciseFeedbackVisible = true;
    },
    async evaluateAnswerSheet() {
      await EvaluateAnswerSheetItem.evaluateAnswerSheet({
        answerSheetId: this.answerSheetId,
      });
    },
    showHintsBottomSheet() {
      this.isHintsBottomSheetVisible = true;
    },
    showInsufficientCoinsModalComponent() {
      this.isInsufficientCoinsModalComponent = true;
    },
    async scrollToTop() {
      await this.$refs.exerciseRef.$el.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    },
    hideDailyExamModal() {
      this.isDailyExamModalVisible = false;
    },
    checkExamType() {
      const { path } = this.$route;
      if (dailyExamPath.test(path)) {
        this.isDailyExam = true;
      }
    },
    openReportIssueModal() {
      this.isReportIssueModalVisible = true;
      this.isExerciseFeedbackVisible = false;
    },
    showFeedbackComponent() {
      this.isExerciseFeedbackVisible = true;
    },
    sendReportIssue() {
      this.showFeedbackComponent();
      this.isReportIssueModalVisible = false;
      this.isSnackbarVisible = true;
      this.isReportIssueDisabled = true;
    },
    async sendFeedback({ text, value, hintId, answerSheetItemId }) {
      this.isSnackbarVisible = false;

      const data = {
        text,
        client: "web",
        score: value,
      };

      if (hintId !== undefined) {
        data.hint_id = hintId;
      } else if (answerSheetItemId !== undefined) {
        data.answer_sheet_item_id = answerSheetItemId;
      }

      await SendFeedback.sendFeedback(data);
      this.isSnackbarVisible = true;
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/_typography.scss";
@import "~@/scss/_colors.scss";

/** @define exam-page; */
.exam-page {
  display: flex;
  width: 100%;
}

/** @define main-content; weak */
.main-content {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;

  @include laptop {
    justify-content: left;
  }
}

.main-content__exercise {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.main-content__evaluate-btn {
  width: 100%;
  margin: 0.5em 0;
  flex-grow: 1;

  @include laptop {
    width: 70%;
    padding-bottom: 5em;
  }
}

.main-content__feedback {
  width: 80%;
  max-width: 590px;
}

.main-content__hints {
  width: 100%;

  @include laptop {
    display: none;
  }
}

.main-content__answers {
  margin: 3em 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  > .exercise-answer {
    flex-grow: 20;
  }

  @include laptop {
    margin-bottom: 1em;
    width: 100%;
    justify-content: left;
    text-align: left;
  }
}

.main-content__floating-button {
  padding-bottom: 13em;
  margin-right: 5%;

  @include laptop {
    display: none;
  }
}

.main-content__hints_bottom_sheet {
  width: 100%;
  position: absolute;
  bottom: 0;
  height: auto;
  max-height: 80%;

  @include laptop {
    display: none;
  }
}

/** @define answers; */
.answers__item {
  @include laptop {
    padding-left: 2em;
  }
}
</style>

<style>
/** @define main-content; weak */
.main-content__previous-btn > button {
  text-align: left;
}
</style>
