<template>
  <!-- eslint-disable-next-line vue/no-mutating-props -->
  <v-bottom-sheet v-model="isVisible" hide-overlay persistent>
    <div :class="'exercise-feedback exercise-feedback--' + styleModifier" data-test-id="exercise-feedback-component">
      <v-btn
        text
        :class="isReportDisabled ? 'exercise-flag-disabled' : 'exercise-flag'"
        :disabled="isReportDisabled"
        data-test-id="flag-button"
        @click="reportIssue"
      >
        <v-icon :size="40">mdi-flag</v-icon>
      </v-btn>
      <p class="exercise-success-feedback__header">{{ feedbackMessage }}</p>
      <BaseButton
        class="exercise-success-feedback__btn"
        style="text-align: justify !important"
        style-modifier="secondary"
        data-test-id="next-button"
        @click="handleButtonClick"
      >
        <slot />
      </BaseButton>
    </div>
  </v-bottom-sheet>
</template>

<script>
import BaseButton from "@/components/base_button/BaseButton.vue";

export default {
  name: "EvaluateExerciseComponent",
  components: {
    BaseButton,
  },
  model: {
    prop: "isVisible",
    event: "hide-feedback-component",
  },
  props: {
    isExerciseCorrect: {
      type: Boolean,
      default: null,
    },
    isVisible: {
      type: Boolean,
      required: true,
    },
    hintsVariant: {
      type: Boolean,
      default: false,
    },
    isReportDisabled: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  computed: {
    feedbackMessage() {
      return this.isExerciseCorrect ? "Respuesta Correcta" : "Respuesta Incorrecta";
    },
    styleModifier() {
      return this.isExerciseCorrect ? "correct" : "incorrect";
    },
  },
  methods: {
    handleButtonClick() {
      return this.hintsVariant && !this.isExerciseCorrect ? this.emitGetHint() : this.emitLoadNextAnswerSheetItem();
    },
    emitLoadNextAnswerSheetItem(event) {
      this.$emit("load-next-answer-sheet-item", event);
    },
    emitGetHint(event) {
      this.$emit("get-hint", event);
    },
    reportIssue() {
      this.$emit("report-issue-question-clicked");
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/_colors.scss";
@import "~@/scss/_typography.scss";

::v-deep .v-btn:not(.v-btn--round).v-size--default {
  height: 0;
}

::v-deep .theme--light.v-btn.v-btn--disabled .v-icon {
  color: $disabled-color !important;
}

.exercise-feedback {
  color: $white;
  padding: 2.5em 2.5em 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  position: relative;

  &--correct {
    background: $success-color;
  }

  &--incorrect {
    background: $error-color;
  }
}

/** @v-dialog */
::v-deep > .v-dialog {
  max-width: 100%;
}

.v-dialog__content {
  z-index: 2 !important;
}

.exercise-correct-feedback {
  background: $success-color;
}

.exercise-incorrect-feedback {
  background: $error-color;
}

.exercise-error-feedback__get-hints-btn {
  display: flex;
  align-self: end;

  @include laptop {
    display: none;
  }
}

.exercise-success-feedback__header {
  @extend %font-title-2;

  text-align: center;
  margin-top: 0;
  padding-bottom: 0.5em;
}

.exercise-success-feedback__sub-header {
  @extend %font-subtitle-1;

  text-align: center;
  margin-top: 0;
}

.exercise-success-feedback__btn {
  display: flex;
  width: 100%;

  @include laptop {
    width: 50%;
  }
}

.exercise-flag {
  cursor: pointer;
  color: $secondary-color;
  position: absolute;
  margin-left: 85%;
  bottom: 9.25em;

  @include laptop {
    margin-left: 49%;
    bottom: 10em;
  }
}

.exercise-flag-disabled {
  cursor: default;
  color: $disabled-color;
  position: absolute;
  margin-left: 85%;
  bottom: 9.25em;

  @include laptop {
    margin-left: 49%;
    bottom: 10em;
  }
}
</style>
