import localForage from "localforage";
import {
  GetActiveCourseProgressIdService,
  GetActiveCourseProgressIdServiceRequest,
} from "@/use_cases/get_active_course_progress_id/GetActiveCourseProgressIdService";

const GetActiveCourseProgressIdResponse = ({ activeCourseProgressId, majorId, courseName }) => ({
  activeCourseProgressId,
  majorId,
  courseName,
});

const getActiveCourseProgressId = async () => {
  const activeCourseProgressId = await localForage.getItem("activeCourseProgressId");
  const majorId = await localForage.getItem("majorId");
  const courseName = await localForage.getItem("activeCourseName");

  if (activeCourseProgressId && majorId && courseName) {
    return GetActiveCourseProgressIdResponse({
      activeCourseProgressId,
      majorId,
      courseName,
    });
  }
  if (activeCourseProgressId) {
    return GetActiveCourseProgressIdResponse({ activeCourseProgressId });
  }

  const serviceRequest = GetActiveCourseProgressIdServiceRequest();
  const service = new GetActiveCourseProgressIdService(serviceRequest);
  const serviceResponse = await service.do();

  if (!activeCourseProgressId) {
    await localForage.setItem("activeCourseProgressId", serviceResponse.activeCourseProgressId);
  }
  if (!majorId) {
    await localForage.setItem("majorId", serviceResponse.majorId);
  }
  if (!courseName) {
    await localForage.setItem("activeCourseProgressId", serviceResponse.activeCourseProgressId);
    await localForage.setItem("majorId", serviceResponse.majorId);
    await localForage.setItem("activeCourseName", serviceResponse.courseName);
  }

  return GetActiveCourseProgressIdResponse(serviceResponse);
};

const GetActiveCourseProgressId = { getActiveCourseProgressId };

export default GetActiveCourseProgressId;
