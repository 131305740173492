<template>
  <div>
    <v-app-bar app color="primary" fixed class="title-bar" elevation="0" height="65">
      <div class="d-flex align-center justify-space-between" style="width: 100%">
        <v-toolbar-title :class="{ 'title-bar__logo--centered': !isQuickMenuVisible }">
          <router-link v-if="isQuickMenuVisible" :to="{ name: 'Home' }"><LogoText /></router-link>
          <LogoText v-else />
        </v-toolbar-title>

        <div
          v-if="isQuickMenuVisible"
          class="title-bar__menu"
          style="position: absolute; left: 50%; transform: translateX(-50%)"
        >
          <v-btn outlined class="title-bar__icon" data-test-id="show-subjects-button" @click="onClickPractice">
            <v-icon large>$vuetify.icons.graduationCap</v-icon>
            <span>Inicio</span>
          </v-btn>

          <v-btn
            outlined
            class="title-bar__icon"
            data-test-id="show-simulation-exams-button"
            @click="onClickSimulationExams"
          >
            <ExamImage class="title-bar__img mt-2 mb-1"></ExamImage>
            <span>Exámenes</span>
          </v-btn>

          <v-btn outlined class="title-bar__icon" data-test-id="show-products-page-button" @click="onClickStore">
            <v-icon class="mt-2 mb-1">$vuetify.icons.store</v-icon>
            <span>Tienda</span>
          </v-btn>
        </div>

        <div class="d-flex align-center">
          <StudentCoinsView v-if="!isSimulationExamMenuIconVisible && isQuickMenuVisible" ref="coins" />
          <v-btn v-if="isSimulationExamMenuIconVisible" icon @click="emitExamImageClick">
            <v-icon class="mt-1 mb-1">$vuetify.icons.exam</v-icon>
          </v-btn>

          <v-btn v-if="isQuickMenuVisible" icon data-test-id="profile-button" @click="onClickProfile">
            <v-icon color="white" size="30">mdi-account</v-icon>
          </v-btn>
        </div>
      </div>
    </v-app-bar>
    <ActiveCourseBar v-if="showCourseBar"></ActiveCourseBar>
  </div>
</template>

<script>
// eslint-disable-next-line import/extensions
import localForage from "localforage";
import LogoText from "@/components/logo_text/LogoText.vue";
import StudentCoinsView from "@/use_cases/show_student_coins/StudentCoinsView.vue";
import ExamImage from "@/assets/exam.svg?inline";
import ActiveCourseBar from "@/components/course_bar/ActiveCourseBar.vue";

export default {
  name: "TitleBarNew",
  components: {
    ActiveCourseBar,
    StudentCoinsView,
    LogoText,
    ExamImage,
  },
  props: {
    isSimulationExamMenuIconVisible: {
      type: Boolean,
      required: true,
    },
    isQuickMenuVisible: {
      type: Boolean,
      required: false,
    },
    showCourseBar: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isMobileResolution: false,
      isLogOutMenuEnabled: false,
    };
  },
  watch: {
    isSimulationExamMenuIconVisible() {
      return this.isSimulationExamMenuIconVisible;
    },
    isQuickMenuVisible() {
      return this.isSimulationExamMenuIconVisible;
    },
  },
  methods: {
    emitExamImageClick() {
      this.$emit("exam-image-click");
    },
    async onClickProfile() {
      await this.$router.push({ name: "ProfilePage" });
    },
    async getCourseProgressId() {
      return localForage.getItem("activeCourseProgressId");
    },
    async onClickPractice() {
      const courseProgressId = await localForage.getItem("activeCourseProgressId");
      await this.$router.push({ name: "ShowSubjectsPage", params: { courseProgressId } });
    },
    async onClickSimulationExams() {
      const courseProgressId = await localForage.getItem("activeCourseProgressId");
      await this.$router.push({ name: "ShowSimulationExamsPage", params: { courseProgressId } });
    },
    async onClickStore() {
      await this.$router.push({ name: "ShowProductsPage" });
    },
    async updateCoinsBalance() {
      await this.$refs.coins.loadBalance();
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/_typography.scss";
@import "~@/scss/_colors.scss";

/** @define title-bar */
.title-bar {
  color: $white;
  padding: 0 5%;
  z-index: 3;
}

.title-bar__img {
  width: 1.4em;
}

.title-bar__logo--centered {
  display: flex;
  justify-content: center;
  width: 100%;
}

.title-bar__icon::v-deep {
  height: 70px !important;
  width: 120px !important;
  border: none !important;

  .v-btn__content {
    height: 100%;
    flex-direction: column;
    color: white;
    text-transform: none;
  }
}

.title-bar__menu {
  display: none;

  @media (min-width: $laptop-width) {
    display: block;
  }
}
</style>
