<template>
  <div class="sidebar">
    <div class="sidebar-box">
      <CoinsProduct
        :promotion-minutes-left="minutesLeft"
        :product="selectedProduct"
        @time-out="timeOut"
        @open-payment-method-modal="openPaymentMethodModal"
      />
    </div>
    <div>
      <PaymentMethodModal
        data-test-id="payment-method-modal"
        :is-visible="isPaymentMethodModalOpen"
        :product="selectedProduct"
        :oxxo-payment-info="oxxoPaymentInfo"
        @hide-modal="closePaymentMethodModal"
        @buy-with="buyWith"
      />
    </div>
  </div>
</template>

<script>
import CoinsProduct from "@/components/coins_product/CoinsProduct.vue";
import localForage from "localforage";
import ShowProducts from "@/use_cases/show_products/ShowProducts";
import BuyProduct from "@/use_cases/buy_product/BuyProduct";
import PaymentMethodModal from "@/use_cases/buy_product/PaymentMethodModal.vue";

export default {
  name: "Sidebar",
  components: { PaymentMethodModal, CoinsProduct },
  data() {
    return {
      /**
       * @typedef Product
       * @type {object}
       * @property {number} productId
       * @property {string} name
       * @property {string} description
       * @property {number} price
       * @property {string} image
       * @property {string} type
       */

      /** @type {Product} * */
      selectedProduct: {},

      /** @type {Product[]} * */
      products: [],

      isPaymentMethodModalOpen: false,

      oxxoPaymentInfo: {},
      bankPaymentInfo: {},

      minutesLeft: 0,
      initialTime: null,
      promotionTime: null,
    };
  },

  async mounted() {
    await this.requestShowProductsService();
    this.initialTime = await localForage.getItem("initialTime");
    this.promotionTime = await localForage.getItem("promotionTime");
    const now = new Date().getTime();
    if (this.promotionTime != null) {
      this.minutesLeft = (this.promotionTime - Math.round((now - this.initialTime) / 1000)) / 60;
    }
  },

  methods: {
    async timeOut() {
      this.minutesLeft = 0;
    },
    async requestShowProductsService() {
      this.products = await ShowProducts.getProducts();
      this.products.push({
        productId: 37,
        name: "6 meses de Ingresapp Premium",
        description:
          // eslint-disable-next-line max-len
          "Acceso ilimitado durante seis meses a toda las funciones de Ingresapp. Recibiras 1 millón de monedas para que las uses como prefieras.",
        price: 159,
        image: "https://ingresapp-api-new.s3.amazonaws.com/media/treasure.png",
        type: "coins_bundle",
      });

      this.selectedProduct = this.products[Math.floor(Math.random() * this.products.length)];

      this.$emit("load-finished");
    },
    openPaymentMethodModal() {
      this.isPaymentMethodModalOpen = true;
    },
    closePaymentMethodModal() {
      this.isPaymentMethodModalOpen = false;
    },
    buyWith(method) {
      switch (method) {
        case "oxxo":
          this.buyWithOxxo();
          break;
      }
    },
    async buyWithOxxo() {
      const { productId } = this.selectedProduct;
      this.oxxoPaymentInfo = await BuyProduct.BuyProductWithOxxo({ productId });
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/_colors.scss";
@import "~@/scss/_typography.scss";
@import "~@/scss/_fonts.scss";

/** @define sidebar; */
.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
