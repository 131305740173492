<template>
  <v-bottom-navigation grow :app="isVisible" class="bottom-navigation">
    <v-btn data-test-id="show-subjects-button" @click="onClickPractice">
      <span>Inicio</span>
      <v-icon large>$vuetify.icons.graduationCap</v-icon>
    </v-btn>

    <v-btn data-test-id="show-simulation-exams-button" @click="onClickSimulationExams">
      <span>Exámenes</span>
      <ExamImage class="bottom-navigation__img mt-2 mb-1"></ExamImage>
    </v-btn>

    <v-btn data-test-id="show-products-page-button" @click="onClickStore">
      <span>Tienda</span>
      <v-icon class="mt-2 mb-2">$vuetify.icons.store</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
import localForage from "localforage";
import ExamImage from "@/assets/exam.svg?inline";

export default {
  name: "BottomNavigationMenu",
  components: {
    ExamImage,
  },
  props: {
    isVisible: {
      type: Boolean,
      required: false,
    },
  },
  methods: {
    async onClickPractice() {
      const courseProgressId = await localForage.getItem("activeCourseProgressId");
      await this.$router.push({ name: "ShowSubjectsPage", params: { courseProgressId } });
    },
    async onClickSimulationExams() {
      const courseProgressId = await localForage.getItem("activeCourseProgressId");
      await this.$router.push({ name: "ShowSimulationExamsPage", params: { courseProgressId } });
    },
    async onClickStore() {
      await this.$router.push({ name: "ShowProductsPage" });
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/_typography.scss";

/** @define bottom-navigation */
.bottom-navigation {
  @extend %font-coins;

  z-index: 2 !important;
  box-shadow:
    0 0 2em 0 rgba(0 0 0 / 30%),
    0 6px 20px 0 rgba(0 0 0 / 19%);
}

.bottom-navigation__img {
  width: 1.75em;
}
</style>
