import { render, staticRenderFns } from "./RegisterAsAStudentPage.vue?vue&type=template&id=596364b2&scoped=true"
import script from "./RegisterAsAStudentPage.vue?vue&type=script&lang=js"
export * from "./RegisterAsAStudentPage.vue?vue&type=script&lang=js"
import style0 from "./RegisterAsAStudentPage.vue?vue&type=style&index=0&id=596364b2&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "596364b2",
  null
  
)

export default component.exports