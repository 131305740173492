<template>
  <div :class="['circle-icon', `circle-icon--${styleModifier}`]" :style="{ fontSize: size }">
    <div class="circle-icon__icon" @click="emitClickEvent">
      <slot name="icon"></slot>
    </div>
    <div class="circle-icon__caption" @click="emitClickEvent">
      <span>{{ caption }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "CircleIcon",
  props: {
    styleModifier: {
      type: String,
      required: false,
      default: "",
      validator: (prop) => ["featured", ""].includes(prop),
    },
    caption: {
      type: String,
      required: false,
      default: "",
    },
    size: {
      type: String,
      default: "1rem",
    },
  },
  methods: {
    emitClickEvent(event) {
      this.$emit("click", event);
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/_typography.scss";

.circle-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 4.25em;

  &--featured {
    width: 6em;
  }
}

.circle-icon__icon {
  width: 3.5em;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  ::v-deep svg {
    width: 60%;
    height: 60%;
  }
}

.circle-icon__caption {
  @extend %font-caption-3;

  margin-top: 0.5em;
  text-align: center;
  cursor: pointer;
}

@include laptop {
  .circle-icon,
  .circle-icon--featured {
    width: 4em;
  }

  .circle-icon__icon {
    width: 4em;
  }
}
</style>
