<template>
  <div class="profile">
    <SnackBar
      :is-visible.sync="isSnackbarVisible"
      :text="snackBarText"
      data-test-id="snackbar"
      @snackbar-closed="onSnackbarClosed"
    ></SnackBar>
    <ContentSidebarLayout>
      <div slot="main-content" class="profile__desktop">
        <v-btn icon class="profile__button-return" data-test-id="button-return" @click="returnHome">
          <v-icon color="black">mdi-arrow-left</v-icon>
        </v-btn>
        <div class="profile__account_container">
          <div class="profile__account">
            <v-icon class="profile__icon" color="white">mdi-account</v-icon>
          </div>
          <div>
            <div class="profile__account-name">{{ profile.fullName }}</div>
            <div class="profile__account-email">{{ profile.email }}</div>
            <div :class="premiumClass">{{ premiumText }}</div>
          </div>
        </div>
        <BaseButton
          v-show="!isPremium"
          style-modifier="secondary"
          class="profile__button-premium"
          data-test-id="button-premium"
          @click="returnShop"
        >
          VOLVERME PREMIUM
        </BaseButton>
        <div class="profile__item">Mis cursos</div>
        <CoursesCard></CoursesCard>
        <div class="profile__options-container">
          <div>
            <div class="profile__item">Nos importa tu opnión</div>
            <div class="profile__section-container">
              <div class="profile__link" data-test-id="app-feedback-link" @click="showAppFeedbackModal">
                <v-icon class="section-container__icon" size="20">mdi-message-text-outline</v-icon>
                Danos tus sugerencias y/o comentarios
              </div>
            </div>
          </div>
          <div>
            <div class="profile__item">Políticas de privacidad</div>
            <div class="profile__section-container">
              <a :href="privacyPolicy" class="profile__link" data-test-id="privacy-policy">
                Ver Políticas de privacidad
              </a>
            </div>
          </div>
          <div>
            <div class="profile__item">¡Invita a tus conocidos y gana!</div>
            <div class="profile__code-container">
              <div>{{ profile.referralCode }}</div>
              <v-btn icon data-test-id="copy-button" @click="copyReferralCode">
                <v-icon color="black" size="20">mdi-content-copy</v-icon>
              </v-btn>
            </div>
            <div class="profile__item_subtitle">
              Ganan 100 monedas: tu amigo al registrarse y tú después de que él complete 5 exámenes
            </div>
          </div>
          <div class="profile__contact_container">
            <div class="contact-container__title">Síguenos en redes</div>
            <div class="contact-container__social-media-icons">
              <div data-test-id="instagram" @click="redirectToInstagram">
                <img :src="require('@/assets/instagram-logo.png')" alt="Instagram" class="contact__icon-size" />
              </div>
              <div data-test-id="facebook" @click="redirectToFacebook">
                <FacebookLogoImage class="contact__icon-size" />
              </div>
              <div data-test-id="tiktok" @click="redirectToTiktok">
                <img :src="require('@/assets/tiktok-logo.png')" alt="Tiktok" class="contact__icon-size" />
              </div>
              <div data-test-id="youtube" @click="redirectToYoutube">
                <img :src="require('@/assets/youtube-logo.png')" alt="Youtube" class="contact__icon-size" />
              </div>
            </div>
          </div>
          <div class="profile__link" data-test-id="logOut" @click="logOut">Cerrar sesión</div>
        </div>
      </div>
      <div v-if="!isPremium" slot="sidebar" data-test-id="premium-card">
        <ProfileCard @return-shop="returnShop"></ProfileCard>
      </div>
    </ContentSidebarLayout>
    <AppFeedbackModal
      v-model="isAppFeedbackModalVisible"
      data-test-id="app-feedback-modal"
      @send-feedback="sendFeedback"
      @hide-modal="isAppFeedbackModalVisible = false"
    >
    </AppFeedbackModal>
  </div>
</template>

<script>
import localForage from "localforage";
import BaseButton from "@/components/base_button/BaseButton.vue";
import ContentSidebarLayout from "@/components/ContentSidebarLayout.vue";
import GetProfile from "@/use_cases/profile/GetProfile";
import ProfileCard from "@/use_cases/profile/ProfileCard.vue";
import SnackBar from "@/components/snack_bar/SnackBar.vue";
import CoursesCard from "@/use_cases/profile/courses/CoursesCard.vue";
import FacebookLogoImage from "@/assets/facebook-logo2.svg?inline";
import AppFeedbackModal from "@/use_cases/profile/AppFeedbackModal.vue";

export default {
  name: "ProfilePage",
  components: {
    CoursesCard,
    AppFeedbackModal,
    SnackBar,
    ProfileCard,
    ContentSidebarLayout,
    BaseButton,
    FacebookLogoImage,
  },
  data() {
    return {
      privacyPolicy: "https://www.ingresapp.xyz/aviso-de-privacidad.html",
      profile: {},
      premiumClass: "profile__account-no-premium",
      premiumText: "",
      isSnackbarVisible: false,
      isPremium: true,
      isAppFeedbackModalVisible: false,
      snackBarText: "",
    };
  },
  async mounted() {
    this.profile = await GetProfile.getProfile();
    this.premiumText = this.checkPremium();
    this.$emit("load-finished");
  },
  methods: {
    async logOut() {
      await localForage.clear();
      await this.$router.push({ name: "Home" });
    },
    checkPremium() {
      if (this.profile.package) {
        this.isPremium = true;
        this.premiumClass = "profile__account-premium";
        return this.profile.package.premium_type;
      }
      this.isPremium = false;
      return "Aún no eres premium";
    },
    async returnHome() {
      await this.$router.push({ name: "Home" });
    },
    async returnShop() {
      await this.$router.push({ name: "ShowProductsPage" });
    },
    copyReferralCode() {
      this.snackBarText = "Copiado";
      navigator.clipboard.writeText(this.profile.referralCode).then(() => {
        this.isSnackbarVisible = true;
      });
    },
    onSnackbarClosed() {
      this.isSnackbarVisible = false;
    },
    redirectToInstagram() {
      window.open(
        // eslint-disable-next-line max-len
        "https://www.instagram.com/ingresapp/",
        "_blank",
      );
    },
    redirectToFacebook() {
      window.open(
        // eslint-disable-next-line max-len
        "https://www.facebook.com/ingresapp/",
        "_blank",
      );
    },
    redirectToTiktok() {
      window.open(
        // eslint-disable-next-line max-len
        "https://www.tiktok.com/@ingresaapp/",
        "_blank",
      );
    },
    redirectToYoutube() {
      window.open(
        // eslint-disable-next-line max-len
        "https://www.youtube.com/channel/UCMaRZ5wpfG5IXe1XIUi0WYw",
        "_blank",
      );
    },
    showAppFeedbackModal() {
      this.isAppFeedbackModalVisible = true;
    },
    sendFeedback() {
      this.snackBarText = "Enviado";
      this.isSnackbarVisible = true;
      this.isAppFeedbackModalVisible = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/scss/_colors.scss";
@import "~@/scss/_typography.scss";

.profile {
  @extend %font-body-1;

  display: flex;
  flex-direction: column;
  align-items: initial;
  justify-content: initial;
  padding: 0 1em 1em;

  @include laptop {
    padding: 0;
  }
}

.profile__desktop {
  @include laptop {
    padding: 0 6em;
  }
}

/** @define profile */

.profile__icon {
  font-size: 3em;

  @include laptop {
    font-size: 4em;
  }
}

.profile__button-return {
  right: 1em;
}

.profile__button-premium {
  margin-bottom: 1em;

  @include laptop {
    display: none;
  }
}

.profile__account_container {
  display: flex;
  gap: 1.5em;
  margin-bottom: 1em;
  align-items: center;
}

.profile__account {
  background: $primary-color;
  width: 4em;
  height: 4em;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  @include laptop {
    width: 5.5em;
    height: 5.5em;
  }
}

.profile__account-name {
  @extend %font-subtitle-1;
}

.profile__account-premium {
  @extend %font-body-2;

  color: $secondary-color;
}

.profile__account-no-premium {
  @extend %font-body-2;

  color: $error-color;
}

.profile__item {
  font-weight: bolder;
}

.profile__item_subtitle {
  @extend %font-body-3;
}

.profile__link {
  @extend %font-body-2;

  color: $primary-color;
  text-decoration: underline;
  cursor: pointer;
}

.profile__options-container {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.profile__code-container {
  background-color: $tertiary-overlay-color;
  max-width: 10em;
  padding-left: 0.75em;
  display: flex;
  gap: 3.5em;
}

.profile__contact_container {
  font-weight: bolder;
  text-align: start;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1em;
}

.profile__section-container {
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  padding-bottom: 0.4em;
}

/** @define section-container */
.section-container__icon {
  color: $primary-color;
}

/** @define contact */
.contact__icon-size {
  cursor: pointer;
  width: 2.3em;
  justify-content: center;
  align-items: center;
}

/** @define contact-container */
.contact-container__title {
  width: 100%;
}

.contact-container__social-media-icons {
  display: flex;
  flex-direction: row;
  gap: 1.5em;
  align-items: center;
}
</style>
